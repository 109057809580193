import React, { useEffect, useState, useRef } from "react";
import integrarIcon from "./assets/LOGOS-V2/LOGOS/logo_integrar_vertical.svg"
import "./global.css"
import buttonData from "./Data";

const App = () => {
  const [expandedIndex, setExpandedIndex] = useState({ state: false, index: 0 });
  const [divPositions, setDivPositions] = useState([]);
  const numDivs = 6;
  const radius = useRef({ v: 350, h: 350, l: false });
  const centerRef = useRef({ x: 0, y: 0 });
  const animationDuration = 20000;
  const animationTimeoutIdRef = useRef(null);
  const startAnimationRef = useRef(null);
  const animationRotateRef = useRef(null);
  const listRef = useRef(null);
  const refComponente = useRef(null);

  const divsRef = useState(
    [
      useRef(null),
      useRef(null),
      useRef(null),
      useRef(null),
      useRef(null),
      useRef(null),
    ]
  );


  useEffect(() => {
    const handleResize = () => {
      const { innerWidth, innerHeight } = window;
      const x = (innerWidth / 2);
      const y = innerHeight / 2;
      centerRef.current = { x, y };
      setExpandedIndex({state: false})
    };

    /**
     * 
     */
    const checkScreenSize = () => {
      const isLarge = window.matchMedia('(min-width: 600px)').matches;
      const isExtraLarge = window.matchMedia('(max-width: 1600px)').matches;
      radius.current = { v: isLarge ? 180 : window.innerHeight / 3, h: isLarge ? 250 : 250, l: isLarge , xl: isExtraLarge}
    };

    checkScreenSize();
    handleResize();

    window.addEventListener('resize', checkScreenSize);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let animationStartTime = null;

    const animateDivs = (timestamp) => {
      if (!animationStartTime) {
        animationStartTime = timestamp;
      }

      const progress = ((timestamp - animationStartTime) % animationDuration) / animationDuration;

      const divPositions = [];
      let position;
      for (let i = 0; i < numDivs; i++) {
        if (expandedIndex.state) {
          divPositions.push(divPositions);
        } else {
          position = getDivPosition(i, progress * 360, progress);
          divPositions.push(position);
        }
      }

      setDivPositions(divPositions);

      animationTimeoutIdRef.current = requestAnimationFrame(animateDivs);
    };

    startAnimationRef.current = {
      startAnimation: () => {
        animateDivs(performance.now());
      }
    };

    startAnimationRef.current.startAnimation();

    return () => {
      clearTimeout(animationTimeoutIdRef?.current);
    };
  }, []);

  /**
   * Expand and retrive colapsable list item
   * @param {*} index 
   */
  const handleClick = (event, index) => {
    if (expandedIndex.state && index == expandedIndex.index) {
      setExpandedIndex({ state: false, index: 0 });
      handleMouseLeave(false)
    } else {
      cancelAnimationFrame(animationTimeoutIdRef.current)
      setExpandedIndex({ state: true, index: index })
    }
    event.stopPropagation();
  };

  /**
   * Handle event when mouse leave from any button rotative and 
   * stop animation
   */
  const handleMouseEnter = () => {
    cancelAnimationFrame(animationTimeoutIdRef.current)
    clearTimeout(animationRotateRef.current)
  };

  /**
   * Handle mouse event when leave from any button rotative and 
   * start animation
   */
  const handleMouseLeave = (state) => {
    if (!radius.current.l) {
      handleMouseEnter()
    }

    animationRotateRef.current = setTimeout(() => {
      if (radius.current.l && !expandedIndex.state) {
        startAnimationRef.current.startAnimation();
      } else if (!state && !radius.current.l) {
        startAnimationRef.current.startAnimation();
      }
    }, 500)

  };

  /**
   * Calculate the position of divs from center
   * @param {*} index 
   * @param {*} angleOffset 
   * @param {*} progress 
   * @returns 
   */
  const getDivPosition = (index, angleOffset = 0, progress) => {
    const angle = (index * 340) / numDivs + angleOffset;
    const radians = (angle * Math.PI) / 180;
    const distanceFromCenterX = radius.current.h;
    const distanceFromCenterY = radius.current.v;
    const x = centerRef.current.x + distanceFromCenterX * Math.cos(radians);
    const y = centerRef.current.y + distanceFromCenterY * Math.sin(radians);
    return { x, y };
  };

  const generateComponentStyle = (position, index, expandedIndex, radius, window) => {
    const isExpanded = expandedIndex.state && expandedIndex.index === index;
    let data = buttonData[index];
    let zIndexValue = 1;
    
    zIndexValue = (expandedIndex.index == index)? 12 : 11

    const flexDirectionValue = position.y < window.innerHeight / 2 ? "column" : "column-reverse";

    let leftValue;
    if (position.x > window.innerWidth * 0.5) {
      leftValue = position.x - (window.innerWidth * 0.5 / 2);
    } else {
      leftValue = position.x + (window.innerWidth * 0.5 / 2);
    }

    leftValue = (radius.current.l) ?leftValue - 100 : leftValue - 85

    let offsetY = (data.items >= 4)? 450 : (data.items) * 104
    const topValue = position.y > window.innerHeight / 2 && isExpanded ?
     `${position.y - offsetY}px` : `${position.y}px`;

    const transitionValue = !isExpanded ? 'all 0.5s ease' : "";

    return {
      zIndex: zIndexValue,
      flexDirection: flexDirectionValue,
      left: leftValue,
      top: topValue,
      transition: transitionValue,
      isExpanded: isExpanded,
      data: data,
      isMidleScreen: position.y > window.innerHeight / 2,
    };
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      const allRefs = divsRef[0].every(ref => !ref?.current?.contains(event.target));
      if (refComponente.current && !refComponente.current.contains(event.target) && allRefs) {
        setExpandedIndex({state: false, index: 0})
        handleMouseLeave(false)
        event.stopPropagation()
      }
    };

    // Agregar el event listener al montar el componente
    document.addEventListener('mousedown', handleClickOutside);

    // Remover el event listener al desmontar el componente
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <div className="container">
      <div className="inner-container">
        <div className={radius.current.l ? "image" : "image-mobile"}>
          <img src={integrarIcon} alt="Logo" />
        </div>
        {divPositions.map((position, index) => {
          const style = generateComponentStyle(position, index, expandedIndex, radius, window);

          return (
            <div
              key={index}
              ref={divsRef[0][index]}
              className="custom-component"
              style={style}
            >
              <button className="text" style={{ 
                flexDirection: "row",
                display: 1,
                width: radius.current.l
                    ? "200px" 
                    : "170px",
                backgroundColor: style.isExpanded 
                  ? "rgba(0,0,0,0.7)" 
                  : "transparent",
                color: "white",
                borderRadius: "10px",
                border: "none",
                opacity: style.isExpanded?
                 0.8 : 1
                 }} onClick={(event) => {
                handleClick(event, index);
              }}
                onMouseEnter={() => handleMouseEnter()}
                onMouseLeave={() => handleMouseLeave()}
              ><div style={{ flex: 1, width: "100%", display: "flex" }}>
                  <p style={{ flex: 1 }} className="text">{style.data.label} </p>
                  {style.isExpanded && <p style={{ flex: 0.1 }} className="text">X</p>}
                </div>
              </button>
              {style.isExpanded && !!style.data.list &&
               <div style={{width: "100%"}} ref={refComponente}>
                {
                style.data.list()
                }
                </div>
              }
            </div>
          );
        })}
        <div className= "footer" /* style={{ position: "absolute", width: "100%", textAlign: "center", zIndex: 10, bottom: (window.innerHeight/window.innerWidth > 2) ? "25vh" : "10vh", color: "white" }} */>
          <p className="text" style={{ width: "100%" }}>
          Let's connect.&nbsp;
            <a href="mailto:hello@integrar.net" style={{ textDecoration: "none", color: "#00B676" }}>hello@integrar.net</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default App;