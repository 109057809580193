import BrulleteLogo from "./assets/LOGOS-V2/LOGOS/Brulette.svg";
import FiveTrhreFourIcon from "./assets/LOGOS-V2/LOGOS/534.svg";
import AllKindBotIcon from "./assets/LOGOS-V2/LOGOS/AllKindBot.svg"
import ChampionsLeagueIcon from "./assets/LOGOS-V2/LOGOS/ChampionsLeague.svg"
import ClubAmericaIcon from "./assets/LOGOS-V2/LOGOS/ClubAmerica.svg"
import ConcacafIcon from "./assets/LOGOS-V2/LOGOS/Concacaf.svg"
import ConcachampIcon from "./assets/LOGOS-V2/LOGOS/Concachamp.svg"
import ConmebolIcon from "./assets/LOGOS-V2/LOGOS/Conmebol.svg"
import Copaamerica2021Icon from "./assets/LOGOS-V2/LOGOS/Copaamerica2021.svg"
import CruzAzulIcon from "./assets/LOGOS-V2/LOGOS/CruzAzul.svg"
import FridaKahloIcon from "./assets/LOGOS-V2/LOGOS/FridaKahlo.svg"
import GoldCupIcon from "./assets/LOGOS-V2/LOGOS/GoldCup.svg"
import HerederosdelCambioIcon from "./assets/LOGOS-V2/LOGOS/HerederosdelCambio.svg"
import ImageArtStudioIcon from "./assets/LOGOS-V2/LOGOS/ImageArtStudio.svg"
import NationsLeagueIcon from "./assets/LOGOS-V2/LOGOS/NationsLeague.svg"
import OEGIcon from "./assets/LOGOS-V2/LOGOS/OEG.svg"
import OvniMangaIcon from "./assets/LOGOS-V2/LOGOS/OvniManga.svg"
import PumasIcon from "./assets/LOGOS-V2/LOGOS/Pumas.svg"
import RBDIcon from "./assets/LOGOS-V2/LOGOS/RBD.svg"
import SymIcon from "./assets/LOGOS-V2/LOGOS/Sym.svg"
import WBSCIcon from "./assets/LOGOS-V2/LOGOS/WBSC.svg"
import WIcon from "./assets/LOGOS-V2/LOGOS/W.svg"
//import StoneBotMangaIcon from "./assets/LOGOS-V2/LOGOS/stonebot2.png"
import StoneBotIcon from "./assets/LOGOS-V2/LOGOS/Stonebot_manga2.png"
import ConosurIcon from "./assets/LOGOS-V2/LOGOS/Conosur2.png"
import DisneyIcon from "./assets/LOGOS-V2/LOGOS/Disney_wordmark.png";
import HarryPotterIcon from "./assets/LOGOS-V2/LOGOS/Harry_Potter_wordmark.png";
import EmagineIcon from "./assets/LOGOS-V2/LOGOS/EMAGINE logotipo.png";
import AtleticoNacionalIcon from "./assets/LOGOS-V2/LOGOS/Atlético_Nacional.png";
import MonterreyIcon from "./assets/LOGOS-V2/LOGOS/Club_de_Fútbol_Monterrey_2019_Logo.png";
import KahloIcon from "./assets/LOGOS-V2/LOGOS/logo-kahlocorporation.png";
import ChapulinIcon from "./assets/LOGOS-V2/LOGOS/Chapulin Logo.png";
import ChavoIcon from "./assets/LOGOS-V2/LOGOS/Chavo_del_8-logo.png";
import ConsejoMundialIcon from "./assets/LOGOS-V2/LOGOS/Consejo_Mundial_de_Lucha_Libre.png";
import CatrinasIcon from "./assets/LOGOS-V2/LOGOS/catrinasx300.png";
import MolangIcon from "./assets/LOGOS-V2/LOGOS/molang.png";
import GigantoSaurusIcon from "./assets/LOGOS-V2/LOGOS/Gigantosaurus.png";
import MikeCrackIcon from "./assets/LOGOS-V2/LOGOS/mikecrack_new__logo.png";
import CheechandIcon from "./assets/LOGOS-V2/LOGOS/cheechandchong.png";
import CasasolaIcon from "./assets/LOGOS-V2/LOGOS/casasola.png";
import ConcafChampionsIcon from "./assets/LOGOS-V2/LOGOS/CONCACAF_Champions_Cup.png";
//import Logo from "./assets/logo.png";
import "./global.css"

const ListSection = ({ items }) => {
  return (
    <ul className="list-container">
      {items.map((item, index, small = false) => {
        return (
          <li className="list-item" key={index}>
            {item.image ? (
              <a href={item.link} target="_blank" style={{justifyContent: "center", display: "flex"}}>
                <img style={(!!item.add_margin) ? !!item.add_lateral_margin ? { alignSelf: "center", objectFit: "contain", marginTop:"15px"} : {"margin-top":"10px"} : {}} className={(!!item.with_auto) ? "list-image with_auto" : "list-image"} src={item.image} alt={item.label} />
              </a>
            ) : (
              item.label
            )}
          </li>
        )
      })}
    </ul>
  );
};

const buttonData = [
  {
    id: 0,
    label: "CONTENT PUBLISHING",
    title: "",
    x: -500,
    y: -500,
    items: 6,
    list: () => {
      const items = [
        { image: AllKindBotIcon, label: "AllKindBotIcon" },
        { image: DisneyIcon , label: "DisneyIcon",  add_margin:true, add_lateral_margin: true},
        { image: StoneBotIcon, label: "StoneBotIcon", link: "https://www.stonebotcomics.com/", with_auto: true, add_margin:true },
        //{ image: StoneBotMangaIcon, with_auto: true, add_margin:true },
        { image: OvniMangaIcon, label: "OvniMangaIcon", link: "https://www.ovnipress.net/manga/" },
        { image: HarryPotterIcon, label: "HarryPotterIcon", add_margin:true, add_lateral_margin: true},
        { image: ConosurIcon, label: "ConosurIcon", with_auto: true},
      ];
      return <ListSection items={items} />;
    },
  },
  {
    id: 1,
    label: "SPECIALIZED CONTENT",
    title: "",
    items: 2,
    list: () => {
      const items = [
        { image: HerederosdelCambioIcon, label: "HerederosdelCambioIcon", link: "https://herederosdelcambio.com/" },
        { image: ImageArtStudioIcon, label: "ImageArtStudioIcon", link: "https://www.imageart.studio/" },
      ];
      return <ListSection items={items} />;
    },
  },
  {
    id: 2,
    label: "LIVE CONTENT",
    title: "",
    items: 2,
    list: () => {
      const items = [
        { image: FridaKahloIcon, label: "FridaKahloIcon", link: "https://fridakahlocorporation.com/" },
        { image: FiveTrhreFourIcon, label: "FiveTrhreFourIcon", link: "https://www.534ent.com/" },
      ];
      return <ListSection items={items} />;
    },
  },
  {
    id: 3,
    label: "CONSUMER PRODUCTS SPORTS",
    title: "",
    items: 3,
    list: () => {
      const items = [
        { image: OEGIcon, label: "OEGIcon", link: "https://www.oeg-sports.com/" },
        { image: ConcacafIcon, label: "ConcacafIcon", link: "https://www.concacaf.com/" },
        { image: WBSCIcon, label: "WBSCIcon", link: "https://www.wbsc.org/en" },
      ];
      return <ListSection items={items} />;
    },
  },
  {
    id: 4,
    label: "E-COMMERCE",
    title: "",
    items: 24,
    list: () => {
      const items = [
        { image: SymIcon, label: "SymIcon", link: "https://www.sym-ent.com" },
        { image: ConcacafIcon, label: "ConcacafIcon", link: "http://www.amazon.com/concacaf" },
        { image: ConmebolIcon, label: "ConmebolIcon", link: "https://www.sym-ent.com"},
        { image: RBDIcon, label: "RBDIcon", link: "http://www.amazon.com/rbd" },
        { image: Copaamerica2021Icon, label: "Copaamerica2021Icon", link: "https://www.sym-ent.com" },
        { image: CruzAzulIcon, label: "CruzAzulIcon", link: "http://www.amazon.com/cruzazul" },
        { image: PumasIcon, label: "PumasIcon", link: "http://www.amazon.com/pumas" },
        { image: ClubAmericaIcon, label: "ClubAmericaIcon", link: "http://www.amazon.com/clubamerica" },
        { image: GoldCupIcon, label: "GoldCupIcon", link: "https://www.amazon.com/stores/page/22F16221-BC8D-47A9-AC97-3385697A8C3C?ingress=3&visitId=8f39e575-3c5a-4fdb-aa01-938dc8a55cb7" },
        { image: NationsLeagueIcon, label: "NationsLeagueIcon", link: "https://www.amazon.com/stores/page/EC055196-8AED-431F-A8C7-E75607FED928?ingress=0&visitId=11078b00-b13d-40ea-8542-dd1623e4c84b" },
        { image: ConcafChampionsIcon, label: "ConcafChampionsIcon", link: "https://www.amazon.com/stores/page/0AEAC518-8110-4005-A462-A5EC1CF71AD7?ingress=0&visitId=1789bb6a-8b23-4363-93a9-777d6bfbcbc7", add_margin: true, add_lateral_margin: true},
        { image: WIcon, label: "WIcon", link: "https://www.amazon.com/stores/page/1D8C9536-FF4D-415D-BB5A-A70F5A69D5E7?ingress=0&visitId=11078b00-b13d-40ea-8542-dd1623e4c84b" },
        { image: AtleticoNacionalIcon, label: "AtleticoNacionalIcon", link: "http://www.amazon.com/atlnacional", add_margin: true, add_lateral_margin: true},
        { image: MonterreyIcon, label: "MonterreyIcon", link: "http://www.amazon.com/rayados", add_margin: true, add_lateral_margin: true},
        { image: KahloIcon, label: "KahloIcon", link: "http://www.amazon.com/fridakahlo", add_margin: true, add_lateral_margin: true},
        { image: ChapulinIcon, label: "ChapulinIcon", link: "http://www.amazon.com/elchavo", add_margin: true, add_lateral_margin: true},
        { image: ChavoIcon, label: "ChavoIcon", link: "http://www.amazon.com/elchavo", add_margin: true, add_lateral_margin: true},
        { image: ConsejoMundialIcon, label: "ConsejoMundialIcon", link: "https://www.amazon.com/cmll", add_margin: true, add_lateral_margin: true},
        { image: CatrinasIcon, label: "CatrinasIcon", link: "http://www.amazon.es/catrinas", add_margin: true, add_lateral_margin: true},
        { image: MolangIcon, label: "MolangIcon", link: "http://www.amazon.com/molang", add_margin: true, add_lateral_margin: true},
        { image: GigantoSaurusIcon, label: "GigantoSaurusIcon", link: "http://www.amazon.com/gigantosaurus", add_margin: true, add_lateral_margin: true},
        { image: MikeCrackIcon, label: "MikeCrackIcon", link: "http://www.amazon.es/mikecrack", add_margin: true, add_lateral_margin: true},
        { image: CheechandIcon, label: "CheechandIcon", link: "https://www.amazon.com/cheechandchong", add_margin: true, add_lateral_margin: true},
        { image: CasasolaIcon, label: "CasasolaIcon", link: "http://www.amazon.com/casasola", add_margin: true, add_lateral_margin: true}
        //{ image: BrulleteLogo },
        //{ image: WBSCIcon },
      ];
      return <ListSection items={items} />;
    },
  },
  {
    id: 5,
    label: "DESTINATION BRAND",
    title: "",
    items: 1,
    list: () => {
      const items = [
        { image: EmagineIcon, label: "EmagineIcon", link: "https://www.emagine.com.ar/", add_margin: true, add_lateral_margin: true },
      ];
      return <ListSection items={items} />;
    },
  },
];

export default buttonData;